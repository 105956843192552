import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/calculationProcessCSS/Confirm.module.css";
import Button from "../../components/common/Button";
import ConfirmPDF from "../../components/calculationProcessPart/ConfirmPDF";
import meritzLogo from "../../assets/meritzLogo.svg";
import { useSelector, useDispatch } from "react-redux";
import { setTotalPrice, setSelectedPlanName } from "../../redux/store";
import { getGenderString } from "../../components/calculationProcessPart/Gender";
import ModifyModal from "../../components/modal/Modifymodal";

function Confirm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userAge, setUserAge] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedStartDate = useSelector(
    (state) => state.calendar.selectedStartDate
  );
  const selectedEndDate = useSelector(
    (state) => state.calendar.selectedEndDate
  );
  const userGender = useSelector((state) => state.user.gender);
  const userDateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const priceData = useSelector((state) => state.priceData.priceData);
  const totalPrice = useSelector((state) => state.totalPrice.totalPrice);
  const selectedData = useSelector((state) => state.plan.selectedData);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ko-KR").format(number);
  };
  const [activePlan, setActivePlan] = useState(() => {
    const savedActivePlan = sessionStorage.getItem("activePlan");
    return savedActivePlan ? savedActivePlan : selectedData.name;
  });

  useEffect(() => {
    const selectedPlan = sessionStorage.getItem("selectedPlan");
    switch (selectedPlan) {
      case "메리츠 알뜰 플랜":
        setActivePlan("BASIC");
        break;
      case "메리츠 고급 플랜":
        setActivePlan("PREMIUM");
        break;
      case "메리츠 추천 플랜":
        setActivePlan("RECOMMEND");
        break;
      default:
        setActivePlan("BASIC");
        break;
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("activePlan", activePlan);
    const selectedPrice = priceData?.[activePlan]?.ttPrem || null;
    dispatch(setTotalPrice(selectedPrice));
  }, [activePlan, priceData, dispatch]);

  useEffect(() => {
    if (userDateOfBirth) {
      const birthYear = parseInt(userDateOfBirth?.substring(0, 4), 10);
      const currentYear = new Date().getFullYear();
      setUserAge(currentYear - birthYear);
    }
  }, [userDateOfBirth]);

  const formatDate = (dateString) => {
    if (!dateString) return "날짜 정보 없음";
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);
    return `${year}년 ${month}월 ${day}일`;
  };

  const quotations = useMemo(() => {
    if (!priceData || !priceData[activePlan]) return [];
    return priceData[activePlan].opapiGnrCoprCtrInspeInfCbcVo[0]
      .opapiGnrCoprCtrQuotCovInfCbcVo;
  }, [priceData, activePlan]);

  const renderQuotations = () => {
    return (
      <div className={styles.dataListWrap}>
        {quotations.map((item, index) => (
          <div key={index} className={styles.dataList}>
            <p className={styles.datalist_text}>특약</p>
            <div className={styles.priceDatas}>
              <p>{item.covNm}</p>
            </div>
            <div className={styles.imagePrice}>
              <p>{formatNumber(parseInt(item.insdAmt))}원</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const nextButton = () => {
    navigate("/signup/member");
  };

  const planData = [
    { name: "알뜰 플랜", dataKey: "BASIC" },
    { name: "고급 플랜", dataKey: "PREMIUM" },
    { name: "추천 플랜", dataKey: "RECOMMEND" },
  ];

  const handlePlanButtonClick = (dataKey, planName) => {
    setActivePlan(dataKey);
    dispatch(setSelectedPlanName(`메리츠 ${planName}`));
    sessionStorage.setItem("activePlan", dataKey);
  };

  const handleModifyClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.confirmContents}>
      <div className={styles.confirmWrap}>
        <h3>선택하신 보장내용과 보험료를 안내해드립니다.</h3>
        <section className={styles.section}>
          <div className={styles.sectionWrap}>
            <div className={styles.sectionWrap_title}>
              <div className={styles.ContentsImgWrap}>
                <img
                  src={meritzLogo}
                  alt="메리츠 로고"
                  className={styles.ContentsImg}
                />
              </div>
              <p>
                {activePlan === "BASIC" && "메리츠 알뜰 플랜"}
                {activePlan === "PREMIUM" && "메리츠 고급 플랜"}
                {activePlan === "RECOMMEND" && "메리츠 추천 플랜"}
              </p>
            </div>
            <div className={styles.section_DataContents}>
              <div className={styles.dataContext}>
                <p>가입내용</p>
                <span
                  onClick={handleModifyClick}
                  className={styles.modifyButton}
                >
                  수정하기
                </span>
              </div>
              <div className={styles.section_DataContentsWrap}>
                <div className={styles.dataFlexbox}>
                  <p>출발 일정일</p>
                  <span>
                    {selectedStartDate
                      ? formatDate(selectedStartDate)
                      : "날짜 정보 없음"}
                  </span>
                </div>
                <div className={styles.dataFlexbox}>
                  <p>도착 일정일</p>
                  <span>
                    {selectedEndDate
                      ? formatDate(selectedEndDate)
                      : "날짜 정보 없음"}
                  </span>
                </div>
                <div className={styles.dataFlexbox}>
                  <p>가입자</p>
                  <span className="flex">
                    {getGenderString(userGender)} (만 {userAge}세) 외{" "}
                    {priceData?.[activePlan]?.inspeCnt
                      ? priceData[activePlan].inspeCnt - 1
                      : 0}{" "}
                    명
                  </span>
                </div>
                <div className={styles.section_cost}>
                  <div className={styles.section_costWrap}>
                    <p>총 보험료</p>
                    <span>
                      {totalPrice
                        ? formatNumber(
                            parseInt(totalPrice.replace(/[^0-9]/g, ""))
                          )
                        : 0}
                      원
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.filterBtn}>
          <div className={styles.filterBtnWrap}>
            <ul>
              {planData.map((plan, index) => (
                <li
                  key={index}
                  className={`cursor-pointer w-full mx-auto ${
                    plan.dataKey === activePlan ? styles.active : ""
                  }`}
                  onClick={() => handlePlanButtonClick(plan.dataKey, plan.name)}
                >
                  {plan.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full">{renderQuotations()}</div>
        </section>
        <ConfirmPDF />
        <ModifyModal isOpen={isModalOpen} onClose={closeModal} />
        <div className={styles.buttonWrap}>
          <div className={styles.buttonWrap_bg}></div>
          <div className={styles.buttonWrap_line}></div>
          <Button buttonText="보험 가입하기" onClick={nextButton} />
        </div>
      </div>
    </div>
  );
}

export default Confirm;
