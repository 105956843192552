import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Badge from "@mui/material/Badge";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { selectEndDate } from "../../redux/store";

function EndCalendar({ onClose, faRetrustData }) {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(dayjs().startOf("day"));
  const maxDate = dayjs().add(3, "month").endOf("month").subtract(1, "day");

  useEffect(() => {
    if (faRetrustData && faRetrustData.endDate) {
      const endDate = dayjs(faRetrustData.endDate);
      setSelectedDate(endDate);
      dispatch(selectEndDate(endDate.format("YYYYMMDD")));
    } else if (startDate) {
      setSelectedDate(dayjs(startDate, "YYYYMMDD"));
    }
  }, [faRetrustData, startDate, dispatch]);

  const shouldDisableDate = (day) => {
    if (startDate) {
      return dayjs(day).isBefore(dayjs(startDate, "YYYYMMDD"), "day");
    }
    return false;
  };

  const handleApply = () => {
    if (selectedDate) {
      dispatch(selectEndDate(selectedDate.format("YYYYMMDD")));
      onClose(selectedDate.format("YYYYMMDD"));
    }
  };

  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const isStartDate = dayjs(day).isSame(dayjs(startDate, "YYYYMMDD"), "day");

    return (
      <Badge
        key={day.toString()}
        overlap="rectangular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={
          isStartDate ? (
            <span className="text-[9px] top-[2px] font-bold text-[#fff] relative right-5 tracking-tighter w-[32px] bg-black text-center">
              출발일
            </span>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
      firstDayOfWeek={0}
    >
      <div
        className="w-full max-w-[320px] mx-auto lg:max-w-[90%] flex flex-col justify-between"
        style={{ height: "900px" }}
      >
        <div className="calendar-container w-full">
          <style>
            {`
              .MuiDateCalendar-root {
                width: 100% !important;
                max-width: none !important;
                height: auto !important;
                overflow: visible !important;
                padding: 0 16px;
                color: #1b2b3e !important;
              }
              .MuiPickersCalendarHeader-root {
                margin-top: 80px !important;
                margin-bottom: 80px !important;
                padding: 0 !important;
              }
              .MuiPickersArrowSwitcher-root {
                gap: 40px !important;
              }
              .MuiPickersArrowSwitcher-button {
                padding: 12px !important;
              }
              .MuiPickersArrowSwitcher-button svg {
                width: 36px !important;
                height: 36px !important;
              }
              .MuiDayCalendar-header, .MuiDayCalendar-weekContainer {
                justify-content: space-between !important;
                margin: 0 !important;
              }
              .MuiDayCalendar-header {
                margin-bottom: 16px !important;
              }
              .MuiDayCalendar-weekContainer {
                margin-bottom: 16px !important;
              }
              .MuiDayCalendar-weekContainer:last-child {
                margin-bottom: 0 !important;
              }
              .MuiDayCalendar-slideTransition {
                overflow: visible !important;
                height: auto !important;
              }
              .MuiTypography-root {
                font-size: 1.1rem !important;
                font-weight: 600 !important;
              }
              .MuiDayCalendar-weekContainer .MuiBadge-root button {
                margin: 0 !important;
                font-size: 1.1rem !important;
                font-weight: 400 !important;
                width: 40px !important;
                height: 40px !important;
                border: none !important;
              }
              .MuiDayCalendar-weekContainer .MuiBadge-root button.Mui-selected {
                background-color: #386937 !important;
                color: white !important;
              }
              .MuiDayCalendar-weekContainer .MuiBadge-root button.MuiPickersDay-today {
                border: none !important;
              }
              .MuiDayCalendar-weekContainer .MuiBadge-root button.MuiPickersDay-today::after {
                content: '';
                position: absolute;
                bottom: 4px;
                left: 50%;
                transform: translateX(-50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #386937;
              }
              .MuiPickersCalendarHeader-label {
                font-size: 1.8rem !important;
                font-weight: 600 !important;
              }
              .MuiDayCalendar-weekDayLabel:first-child,
              .MuiDayCalendar-weekContainer .MuiBadge-root:first-child button {
                color: #e57373 !important;
              }
              .MuiDayCalendar-weekDayLabel:last-child,
              .MuiDayCalendar-weekContainer .MuiBadge-root:last-child button {
                color: #4a90e2 !important;
              }
              .MuiDayCalendar-weekContainer .MuiBadge-root button.Mui-disabled {
                opacity: 0.5 !important;
                text-decoration: line-through !important;
              }
              @media (min-width: 1024px) {
                .MuiDateCalendar-root {
                  font-size: 1.2rem;
                  padding: 0 24px;
                }
                .MuiTypography-root {
                  font-size: 1.2rem !important;
                }
                .MuiDayCalendar-weekContainer .MuiBadge-root button {
                  width: 48px !important;
                  height: 48px !important;
                  font-size: 1.2rem !important;
                }
                .MuiDayCalendar-weekContainer {
                  margin-bottom: 24px !important;
                }
                .MuiPickersCalendarHeader-label {
                  font-size: 2rem !important;
                }
                .MuiPickersArrowSwitcher-button svg {
                  width: 44px !important;
                  height: 44px !important;
                }
              }
            `}
          </style>
          <DateCalendar
            value={selectedDate}
            onChange={(newDate) => {
              setSelectedDate(newDate);
              dispatch(selectEndDate(newDate.format("YYYYMMDD")));
            }}
            shouldDisableDate={shouldDisableDate}
            showDaysOutsideCurrentMonth={false}
            minDate={minDate}
            maxDate={maxDate}
            views={["month", "day"]}
            className="custom-calendar w-full"
            slots={{
              day: ServerDay,
            }}
          />
        </div>

        <div className="w-full lg:max-w-[100%] lg:mx-auto">
          <div className="mt-4 border-t-2"></div>

          <div className="py-4 lg:py-6">
            <button
              className="w-full mx-auto text-center py-5 rounded-2xl bg-[#386937] text-white font-semibold text-lg lg:w-[50%] lg:flex lg:justify-center lg:py-6 lg:text-xl"
              onClick={handleApply}
            >
              적용
            </button>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default EndCalendar;
