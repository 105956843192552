import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary";
import Router from "./share/Router";

function App() {
  return (
    <div className="layoutWrapper">
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </div>
  );
}

export default App;
