const IndividualInfo = [
  {
    content: "개인정보처리방침",
    dates: ["공고일: 2024.07.02일", "기준일: 2024.07.02일"],
    describe:
      "주식회사 리트러스트(이하 '회사')는 「개인정보보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」등 관련 법령에 따라 정보주체의 개인(신용)정보를 보호하고 이와 관련한 정보주체의 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 <개인(신용)정보 처리방침>을 수립ㆍ공개합니다.",

    title: "제1조 개인(신용)정보 처리목적 및 처리항목",
    sections: [
      {
        section:
          "1. 회사는 앱 또는 웹을 통한 회원가입 및 서비스 이용에 반드시 필요한 개인(신용)정보를 다음과 같은 목적을 위하여 처리합니다. 비회원 이지만 회사의 보험상품에 가입하거나, 가입한 경우에도 처리하는 개인(신용)정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행 합니다.",
        subSections: [
          "1) 서비스 제공 및 계약이행",
          "- 서비스 제공에 따르는 본인인증",
          "- 구매 및 요금 결제",
          "- 원활한 고객상담",
          "- 고객 맞춤 서비스 제공",
          "- 고객센터(피해 사고 신고): 서비스 이용에 따른 각종 피해 또는 사고접수 및 신속한 처리",
        ],
      },
      {
        subSections: [
          "2) 법령상 의무이행, 회원가입, 회원관리 및 부정이용 방지",
          "- 회원가입의사 확인, 개인식별, 회원탈퇴 의사확인",
          "- 각종 법령 및 인슈어트러스트서비스 이용약관을 위반하는 회원에 대한 이용 제한 조치",
          "- 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재",
          "- 계정도용 및 부정거래 방지",
          "- 공지사항 전달",
          "- 분쟁조정 기록의 보존, 민원처리",
          "- 이용자 보호 및 서비스 운영",
          "- 보안, 프라이버시, 안전한 서비스 이용환경 구축",
        ],
      },
      {
        subSections: [
          "3) 마케팅 활용 등",
          "- 이벤트 정보, 광고성 정보 제공",
          "- 마케팅 및 프로모션",
          "- 서비스 이용기록과 접속빈도 분석, 서비스 이용 통계",
          "- 맞춤형 서비스 제공 및 광고 게재",
        ],
      },
    ],
  },
  {
    sections: [
      {
        section: "2. 개인(신용정보) 처리항목은 아래와 같습니다.",
        subSections: [
          "1) 본인인증",
          "- 필수항목: 이름, 휴대전화번호, CI(연계정보), DI(중복가입확인정보), 성별, 생년월일, 내외국인구분, 통신사, 이메일주소",
        ],
      },
      {
        subSections: [
          "2) 본인인증(카카오톡Klip인증)",
          "- 필수항목: 인증결과값 및 블록체인 지갑주소정보",
        ],
      },
      {
        subSections: [
          "3) 구매 및 요금결제",
          "- 필수항목: 이름(카드소유자명), 카드번호(암호화)유효기간, 생년월일,",
          "- 선택항목: 카드비밀번호 앞 2자리, (옵션으로 CVS 3자리)",
        ],
      },
      {
        subSections: [
          "4) 보험 계약체결(동반 가입 포함)",
          "- 필수항목: (피보험자 정보): 이름(국/영문), 휴대전화번호, 이메일주소, ",
          "주민등록번호(보험사로 전달)",
        ],
      },
      {
        subSections: [
          "5) 보험계약정보",
          "- 필수항목: 보험사, 상품명, 보험료, 보험기간, 납입방법, 보장내용, 법정대리인 정보",
          "수익자정보, 주민등록번호(암호화하여 보관)",
        ],
      },
      {
        subSections: [
          "6) 보험 가입내역확인/청구/조회/지급내역확인",
          "- 필수항목: 청구인 및 피보험자 이름, 주민등록번호(보험사로 전달), 휴대전화번호, 수익자 은행 및 계좌번호, 예금주명, 진료이용내역, 청구 보험사 정보",
        ],
      },
      {
        subSections: [
          "7)고객상담",
          "- 필수항목: 이름, 생년월일, 성별, 고유식별번호, 휴대전화번호, 연락처, 직업",
        ],
      },
      {
        subSections: [
          "8) 고객센터(피해 사고 신고)",
          "- 필수항목: 이름, 휴대전화번호, 생년월일, 피해내역, 상세사유",
        ],
      },
      {
        subSections: [
          "9.고객상담(채널톡 상담)",
          "- 필수항목: 이름, 이메일, 휴대전화번호, 상담내용",
        ],
      },
      {
        subSections: ["10. 공지사항 전달", "- 필수항목: 이름, 이메일"],
      },
      {
        subSections: [
          "11. 마케팅 및 프로모션",
          "- 필수항목:",
          "(프로모션 참가시) 이름, 이메일주소, 주민등록번호, 휴대전화번호 등",
          "(프로모션 당첨시) 경품배송을 위한 이름, 휴대전화번호, 이메일 주소, 배송주소",
          "(제세공과금 부과시) 이름, 주민등록번호",
        ],
      },
    ],
  },

  {
    title: "제2조 개인(신용)정보의 수집방법",
    sections: [
      {
        section:
          "3. 이미 동의한 개인(신용)정보의 수집과 이용은 취소하고 삭제할 수 있습니다. 다만, 결제와 같은 필수적 서비스에 대해서는 취소 기능이 제공되지 않을 수도 있습니다. 만약, 특정 서비스의 개인(신용)정보 수집ㆍ이용 동의를 철회하는 경우에는 해당 서비스의 이용은 불가능하지만 다른 서비스의 이용은 가능합니다.",
      },
      {
        section: "",
        subSections: [
          "· 회사는 아래와 같은 방법을 통하여 개인(신용)정보를 수집합니다.",
          "- 회원가입 및 서비스 이용 과정에서 이용자가 개인(신용)정보 수집에 대해 동의하고 직접 정보를 입력하는 경우",
          "- 상담신청, 서비스 신청, 이벤트 참가시 개인(신용)정보 입력란에 본인이 직접 입력하는 방식",
          "- 회사 홈페이지, 서면, 팩스, 전화 등을 통한 개인(신용)정보 수집",
          "- 제휴 서비스 또는 단체로부터 개인(신용)정보를 제공받은 경우",
          "- 보험상품 동반가입신청시, 대표자로부터 동반가입자들의 개인(신용)정보를 제공받는 경우",
        ],
      },
    ],
  },
  {
    title: "제3조 자동 수집 장치의 설치/운영 및 거부에 관한 사항",
    sections: [
      {
        section: "",
        subSections: [
          "1. 정보 수집",
          "· 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다",
          "- IP주소, 쿠키, 방문일시, 서비스 이용기록, 불량 이용기록, 기기정보 (모델명, OS, OS 버전, 앱 버전, ADID, IDFA), 결제기록, 접속기록 등",
          "1) 생성정보 수집항목",
          "- 회원의 서비스 설치, 방문이력",
          "- 회원의 서비스 검색/결제/구매/이용이력",
          "- 기타 규제기관 가이드라인에 따라 생성정보로 인정되는 온라인상의 회원의 활동정보",
          "2) 생성정보 수집목적",
          "- 회원이 이용한 회사의 각 서비스와 이용행태 등을 분석하여 회원에게 최적화된 개인 맞춤형 서비스 제공시 활용",
          "- 신규 서비스 개발, 서비스 개편 시 데이터로 활용",
          "- 서비스 이용통계",
          "3) 생성정보 보유ㆍ이용 기간 및 이후 정보처리 방법",
          "- 회사는 회원의 생성정보를 서비스 회원탈퇴 시 또는 위탁 계약 종료시까지 저장합니다.",
          "4) 생성정보 관련 문의 및 피해구제 방법 등 문의",
          "- 전화: 051-959-9901",
          "- 이메일: support@retrust.world",
          "2. 쿠키(cookie) 사용",
          "1) “인슈어트러스트” 웹 및 앱은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.",
          "2) 쿠키는 이용자의 브라우저에게 보내는 소량의 정보이며 이용자들의 모바일내 저장되기도 합니다.",
          "3) 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, “보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.",
          "4) 쿠키의 설치•운영 및 거부",
          "- 안드로이드폰 : 구글설정 광고 → 광고 맞춤 설정 선택 또는 해제",
          "- 아이폰 : 아이폰설정 → 개인정보보호 → 광고 → 광고 추적 제한",
          "5) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.",
        ],
      },
    ],
  },
  {
    title: "제4조 개인(신용)정보 처리 및 보유기간",
    sections: [
      {
        subSections: [
          "회사는 법령에 따른 개인(신용)정보의 처리 및 보유기간 또는 정보주체로부터 개인(신용)정보를 수집시에 동의받은 개인(신용)정보 보유기간 내에서 개인(신용)정보를 처리합니다. 회사는 회원이 탈퇴를 요청하거나 개인(신용)정보 수집ㆍ이용에 대한 동의를 철회하는 경우, 수집ㆍ이용 목적이 달성되거나 보유기간이 종료한 경우에는 해당 개인(신용)정보를 본 조에서 정한 바에 따라 파기합니다.",
        ],
      },
      {
        section: "1. 원칙",
        subSections: [
          "‘1) 상법 등 관련 법령에서 규정한 보존기간 동안 거래 내역과 최소한의 기본정보를 보유",
          "‘2) 홈페이지 회원이 회원 탈퇴 요청, 개인(신용)정보의 수집·이용 등에 대한 동의 철회, ‘개인(신용)정보 삭제 또는 파기 요청 시까지 보유·이용하며, 수집·이용 목적을 달성한 경우 또는 탈퇴·동의 철회의 요청이 있는 경우 해당 개인(신용)정보를 즉시 파기합니다",
          "‘3) 또한 장기 미로그인(로그인을 하지 않고 1년 경과) 고객은 고객계정을 휴면계정으로 전환하고, 별도의 장치에 안전하게 보관합니다. 다만, 다음 제2항의 경우에는 예외로 합니다.",
          "‘4) 동반가입의 경우도 동일하게 처리합니다.",
        ],
      },
    ],
  },
  {
    sections: [
      {
        section: "2. 예외",
        subSections: [
          "1) 고객님이 당사에 대하여 별도의 ‘개인(신용)정보에 제공 등에 대한 동의’를 한 경우(금융거래의 체결·유지·관리 목적 등)로서 해당 동의 목적으로 위 개인(신용)정보가 수집 및 이용되는 경우에는 해당 동의에 따른 보유 및 이용기간이 적용됩니다.",
          "- 전자금융거래에 관한 기록: 5년",
          "- 표시·광고에 관한 기록: 6개월",
          "- 부정이용 등에 관한 기록: 5년",
          "- 공인전자주소를 통한 전자문서 유통에 관한 기록: 10년",
          "- 계약 또는 청약철회 등에 관한 기록: 5년(『전자상거래 등에서의 소비자보호에 관한 법률』 제6조 및 동법 시행령 제6조)",
          "- 대금결제 및 재화 등의 공급에 관한 기록: 5년(『전자상거래 등에서의 소비자보호에 관한 법률』 제6조 및 동법 시행령 제6조)",
          "- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(『전자상거래 등에서의 소비자보호에 관한 법률』 제6조 및 동법 시행령 제6조)",
          "- 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년(『신용정보의 이용 및 보호에 관한 법률』 제20조)",
          "- 서비스 이용 관련 개인(신용)정보(서비스 방문기록): 3개월(통신비밀보호법)",
          "2) 수집된 개인(신용)정보의 보유 및 이용 기간은 정보 수집에 관한 동의를 받을 때 기간을 명시한 경우에는 그 보유기간까지, 명시하지 않은 경우에는 이용계약종료(회원탈퇴) 시점까지입니다. 회사는 회원의 개인(신용)정보를 상기 명시한 사유에 따라 일정 기간 저장하는 자료를 제외하고는 기간 만료 후 지체 없이 파기하며 개인(신용)정보 취급이 제3자에게 위탁된 경우에는 수탁자도 파기하도록 지시합니다.",
          "",
          "3) 프로모션 및 마케팅 목적으로 수집된 개인(신용)정보",
          "- 목적: 프로모션 당첨자 선정 및 프로모션 진행",
          "- 보유 및 이용기간: 최대 3개월(프로모션마다 상이할 수 있으며 개별 프로모션 페이지에 기재된 기간을 우선합니다.)",
          "‘4) 1년 동안 회사의 서비스를 이용하지 않은 회원의 개인(신용)정보는 『개인정보보호법』 제39조의6에 근거하여 회원에게 사전통지하고 개인(신용)정보를 파기하거나 별도로 분리하여 저장합니다. 단, 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 관련 법령에서 규정한 일정한 기간 동안 회원 개인(신용)정보를 보관합니다",
          "‘5) 기타 법률에 의해 이용자의 개인(신용)정보를 보존해야 할 필요가 있는 경우에는 해당 법률의 규정에 따릅니다.",
        ],
      },
    ],
  },
  {
    title: "제4조의 2 만 14세 미만 아동의 개인(신용)정보 처리에 관한 사항",
    sections: [
      {
        subSections: [
          "1. 회사는 만 14세 미만 아동에 대해 개인(신용)정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인(신용)정보를 수집합니다.",
          "- 필수항목 : 법정 대리인의 성명, 관계, 연락처",
          "2. 회사는 만 14세 미만 아동의 개인(신용)정보를 수집할 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를 확인합니다.",
          "- 법정대리인이 자신의 정보를 입력하는 페이지에서 만 14세 미만 아동을 동반자로 추가하여 개인(신용)정보 수집을 위한 내용을 입력 하는 방법.",
          "- 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 개인(신용)정보처리자가 그 동의 표시를 확인했음을 법정대리인의 휴대전화 문자메시지로 알리는 방법",
          "- 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를 확인하는 방법",
          "- 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후 제출하도록 하는 방법",
          "- 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법",
          "- 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를 통하여 동의를 얻는 방법",
          "- 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법",
        ],
      },
    ],
  },
  {
    title: "제5조 개인(신용)정보의 제3자 제공",
    sections: [
      {
        section: "1. 원칙",
        subSections: [
          "1) 회원은 개인(신용)정보의 제3자 제공에 대하여 동의를 하지 않을 수 있고, 언제든지 제3자 제공 동의를 철회할 수 있습니다. 동의를 거부하시는 경우에도 회원가입 서비스는 이용하실 수 있으나, 제3자 제공에 기반한 관련 서비스의 이용 및 제공이 제한될 수 있습니다.",
          "2) 앱 또는 웹(홈페이지)에서 수집된 개인(신용)정보는 당사의 업무위탁과 관계없이는 원칙적으로 외부에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.",
          "3) 동반가입의 경우, 일반회원 및 준회원에 준하여 처리합니다.",
          "4) 제3자제공 현황",
          "(1) 보험사",
          "- 보험사상세: 메리츠화재보험㈜",
          "- 목적:",
          "(가) 보험계약을 제공하는 보험회사에 해당 계약체결위한 정보를 제공",
          "(나) 고객문의사항 상담 및 응대(전화, 이메일 등)",
          "- 제공 정보항목",
          "(가) 보험 계약을 원하는 회원의 국문성명, 영문성명, 생년월일, 성별, 휴대전화번호, 이메일, 결제정보, 동반자정보, 개인식별정보(CI정보, 외국인등록번호), 주민등록번호(암호화하여 전달)",
          "(나) 보험 청구를 원하는 회원의 국문성명, 주민등록번호(암호화하여 전달), 연락처, 이메일",
          "(다) 계약전 알릴의무사항(피보험자의 질병 및 상해정보, 법률 및 국제협약 등의 의무이행을 위한 정보, 금융거래 업무 등) 등 보험계약에 필요한 정보",
          "(라) 보험상품에 따라 추가적인 개인(신용)정보 수집이 될 수 있으며 보험 계약체결 시 별도 동의를 획득",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 보험계약의 해지 또는 만료 시까지 (단, 관계법령의 규정에 의해 보존의 필요가 있는 경우 해당 보유기간까지)",
          "(3) ㈜나이스평가정보",
          "- 목적: 홈페이지 회원가입 및 보험청구시 실명확인",
          "- 제공항목: 이름, 휴대전화번호, 생년월일, 성별, 연계정보(CI), 중복가입확인정보(DI)",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 수집 및 이용목적의 달성 시 파기. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 보관",
          "(4) ㈜KT알파",
          "- 목적: 기프티샵 운영(선물 내역관리, 상품정보 공유, 배송정보 등)",
          "- 제공항목: 휴대전화번호, 개인(신용)정보포함 메시지내용",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 서비스/상품 계약 종료 시까지 또는 관계법령에 따른 보존기간",
          "(5) ㈜알리는사람들",
          "- 목적: 고객대상 문자메시지 발송",
          "- 제공항목: 휴대전화번호, 개인(신용)정보포함 메시지내용",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 서비스/상품 계약 종료 시까지 또는 관계법령에 따른 보존기간",
          "(6) 이벤트 당첨자 경품배송업체 (구체적인 업체명은 이벤트 행사시 별도로 알려드림)",
          "- 목적: 이벤트 당첨자 경품배송",
          "- 제공항목: 이름, 연락처(휴대전화번호), 이메일주소, 배송주소",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 서비스/상품 계약 종료 시까지 또는 관계법령에 따른 보존기간",
          "(7) 이벤트 대행업체 (구체적인 업체명은 이벤트진행시 별도로 알려드림)",
          "- 목적: 이벤트 대행",
          "- 제공항목: 이름, 이메일주소,주민등록번호,연락처(휴대전화번호)",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 서비스/상품 계약 종료 시까지 또는 관계법령에 따른 보존기간",
          "(8) Adjust, Singular, Google Analytics",
          "- 목적: 웹, 모바일 사용자 대상 행태정보 등 자동수집",
          "- 제공항목: 기기식별번호, 거래금액, 메뉴이동정보",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 수집 및 이용목적의 달성 시 파기. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 보관",
          "(9) ㈜코리아포트원, 나이스페이먼츠㈜",
          "- 목적: 결제대행서비스(신용카드 및 계좌이체 등)",
          "- 제공항목: 이름, 연락처(휴대전화번호), 이메일주소, 상품명, 결제금액, 신용카드번호",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 요금결제·정산 완료시까지. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 보관",
          "(15) 주식회사 카카오",
          "- 목적: Klip지갑을 이용한 소셜로그인을 이용한 회원가입/로그인시 본인확인",
          "- 제공정보항목: 이름, 연락처(휴대전화번호), 이메일주소",
          "- 개인(신용)정보를 제공받는 자의 보유기간: 수집 및 이용목적의 달성 시 파기. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 보관",
        ],
      },
      {
        section: "2. 예외",
        subSections: [
          "1) 회사는 이용자의 개인(신용)정보를 본 「개인(신용)정보 처리지침」 ",
          "제4조(개인(신용)정보의 보유 및 이용기간)에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 수집목적 범위를 초과하여 이용하거나 당사의 업무위탁과 관계없이 원칙적으로 제3자에게 제공하지 않습니다.",
          "2) 다만 양질의 서비스 제공을 위해 이용자의 개인(신용)정보를 제휴사 또는 개인(신용)정보 수탁업체에 제공하는 경우에는 사전에 이용자에게 업체명, 제공되는 개인정보 항목, 제공 목적, 보유기간 등에 대해서 고지하고 개별적으로 동의를 구하는 절차를 거칩니다. 동의가 없는 경우에는 제공되지 않으며, 제휴사 및 개인(신용)정보 수탁업체가 변경된 경우에도 이를 고지하고 개별적으로 동의를 구하는 절차를 거칩니다. 단 다음의 경우에는 예외로 합니다.",
          "- 법률의 규정이 있는 경우",
          "- 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요청이 있는 경우",
          "- 통계 작성, 학술 연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우",
          "- 『개인정보보호법』 제28조의 2(가명정보의 처리 등) 제1항('개인정보처리자는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 정보 주체의 동의 없이 가명정보 처리를 할 수 있다.')에 따라 통계 작성(상업적 목적 포함), 연구(산업적 연구 포함), 공익적 기록보존 목적 등을 위해 가명 처리하여 제공하는 경우",
          "- 고객 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 고객 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우",
          "3) 고객의 동의가 있는 경우",
          "- 서비스/고객 분석 및 통계, 콘텐츠 개발 등의 원활한 이행을 위하여 가입시 기재한 정보에 한해 아래 지정한 자에게 정보를 제공합니다. 제공하는 개인(신용)정보는 암호화되어 전달되며 목적에 필요한 최소한의 범위 내에서만 활용됩니다.",
          "4) 다음의 경우 회사는 회원의 개인(신용)정보의 이용, 제공 등에 제한을 받지 않습니다.",
          "- 서비스 제공에 따른 요금정산, 추심을 위하여 필요한 경우",
          "- 회원이 사전에 동의한 경우",
          "- 법령의 규정에 의거한 경우",
        ],
      },
    ],
  },
  {
    title: "제6조 개인(신용)정보의 처리의 위탁",
    sections: [
      {
        section: "",
        subSections: [
          "· 회사는 서비스 향상 및 업무의 효율적 수행을 위하여 개인(신용)정보의 처리를 위탁하고 있으며, 위탁업무의 목적 및 위탁업체는 다음과 같습니다.",
          "1) 보험사",
          "- 보험사 상세: 메리츠화재보험㈜",
          "- 위탁업무의 목적",
          "(1) 보험계약을 제공하는 보험회사에 해당 계약정보를 제공(보험 계약을 원하는 회원의 국문성명, 영문성명, 생년월일, 휴대전화번호, 이메일, 결제정보, 동반자 정보, 개인식별정보(CI정보, 외국인등록번호), 계약 전 알릴 의무사항(피보험자의 질병 및 상해정보, 법률 및 국제협약 등의 의무이행을 위한 정보, 금융거래 업무 등) 등 보험계약에 필요한 정보, 보험상품에 따라 추가적인 개인(신용)정보 수집이 될 수 있으며 보험 계약 체결 시 별도 동의를 획득)",
          "(2) 보험금 청구서비스의 대행(기웅정보통신㈜)",
          "(3) 고객문의사항 상담 및 응대(전화, 이메일 등)",
          "- 연락처: 개별연락처",
          "2) ㈜xx",
          "- 위탁업무의 목적: 보험금청구 실명확인",
          "- 연락처: ",
          "3) ㈜KT알파",
          "- 위탁업무의 목적: 기프티샵 운영(선물 내역관리, 상품정보 공유, 배송정보 등)",
          "- 연락처: ",
          "4) ㈜알리는사람들",
          "- 위탁업무의 목적: 고객대상 문자메시지 발송",
          "- 연락처: 02-511-4560",
          "5) 이벤트 당첨자 경품배송업체 (구체적인 업체명은 이벤트 행사시 별도로 알려드림)",
          "- 위탁업무의 목적: 이벤트 당첨자 경품배송",
          "- 연락처: 이벤트 경품배송업체 결정시 안내",
          "6) 이벤트 대행업체 (구체적인 업체명은 이벤트진행시 별도로 알려드림)",
          "- 위탁업무의 목적: 이벤트 대행",
          "- 연락처: 이벤트 대행업체 결정시 안내",
          "7) 기웅정보통신㈜",
          "- 위탁업무의 목적: 보험계약정보 및 진료내역, 보험금청구 등 정보 수집",
          "- 연락처: 02-3463-5975",
          "8) Adjust, Singular, Google Analytics",
          "- 위탁업무의 목적: 웹, 모바일 사용자 대상 행태정보 등 자동수집",
          "- 연락처:",
          "(1) Google Analytics: 02-737-0346 (국내대리인)",
          "9) 나이스페이먼츠㈜",
          "- 위탁업무의 목적: 결제대행서비스(신용카드 및 계좌이체 등)",
          "- 연락처:",
          "(1) 나이스페이먼츠㈜: 1661-0808",
          "10) 나이스평가정보",
          "- 위탁업무의 목적: 주민등록번호 진위여부 확인",
          "- 연락처: 02-2122-4000",
          "11) 주식회사 카카오, NHN 주식회사, Apple Inc.",
          "- 위탁업무의 목적: Klip지갑 소셜로그인을 이용한 회원가입/로그인시 본인확인",
          "- 연락처:",
          "(1) 주식회사 카카오: 1577-3754",
        ],
      },
      {
        section: "※ 상기 위탁 받는 자의 연락처는 확보 되는대로 공지하겠습니다.",
      },
    ],
  },
  {
    title: "제7조 개인(신용)정보의 파기절차 및 방법",
    sections: [
      {
        section: "",
        subSections: [
          "1. 회사는 「개인(신용)정보 처리지침」 제4조(개인(신용)정보의 보유 및 이용기간)에 규정된 개인(신용)정보의 보유 및 이용기간 만료 30일전까지 개인(신용)정보가 파기되거나 분리되어 저장·관리되는 사실과 기간 만료일 및 해당 개인(신용)정보의 항목을 공지사항, 이메일 등의 방법으로 회원에게 알립니다. 이를 위해 회원은 회사에 정확한 연락처 정보를 제공·수정하여야 합니다.",
          "2. 회원의 개인(신용)정보는 수집 및 이용 목적이 달성된 후에는 지체없이 파기됩니다. 종이에 출력된 개인(신용)정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기하고, 전자적 파일 형태로 저장된 개인(신용)정보는 기록을 재생할 수 없는 기술적 방법 또는 물리적 방법을 사용하여 파기합니다. 개인(신용)정보 파기의 절차, 기한 및 방법은 다음과 같습니다.",
          "① 파기절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부규정 및 기타 관련 법령에 따라 일정 기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인(신용)정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다",
          "② 파기기한: (개인(신용)정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등) 이용자의 개인(신용)정보 보유기간이 경과된 경우, 개인(신용)정보가 불필요한 것으로 인정되는 날로부터 영업일 5일 이내에 파기합니다.",
          "③ 파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적인 방법을 사용합니다. 종이에 출력된 개인(신용)정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.",
          "3. 동반가입의 경우, 일반회원 및 준회원에 준하여 처리합니다.",
        ],
      },
    ],
  },
  {
    title: "제 8조 고객(회원)의 권리·의무",
    sections: [
      {
        section: "1. 고객(회원)의 권리",
        subSections: [
          "1) 회원은 언제든지 자신의 개인(신용)정보를 열람, 정정 처리할 수 있으며, 회원의 개인(신용)정보가 제3자에게 제공되거나 취급 위탁된 경우 회원은 회사 또는 제3자, 수탁자에게 파기를 요청할 수 있습니다. 단, 회원 아이디(ID) 및 성명은 정정이 불가능하며, 개명으로 인한 성명 변경은 예외적으로 허용될 수 있습니다.",
          "2) 기타 법률에 따라 정정 또는 삭제가 금지되거나 제한되어 있는 경우에는 해당 처리가 제한될 수 있습니다. 또한 개인(신용)정보 오류에 대한 정정 요청한 경우에는 다른 법률에 따라 개인(신용)정보의 제공을 요청받는 경우가 아닌 한 정정을 완료하기 전까지 당해 개인(신용)정보를 이용 또는 제공하지 않습니다. 만약 잘못된 개인(신용)정보를 이미 제공한 경우에는 정정처리결과를 제3자에게 통지하여 정정이 이루어지도록 하겠습니다.",
          "3) 회원은 언제든지 개인(신용)정보에 대하여 처리의 정지를 요구할 수 있습니다. 다만 아래의 경우에 해당하는 경우 처리정지 요구를 거절할 수 있습니다",
          "① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우",
          "② 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우",
          "③ 개인(신용)정보를 처리하지 아니하면 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 회원이 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우",
          "4) 회원은 개인(신용)정보의 수집·이용·제공에 대해 동의한 내용을 언제든지 철회할 수 있습니다. 다만 법률 또는 약관의 규정에 따라 회사가 회원의 개인(신용)정보를 보존하여야 하는 경우에는 해당 처리가 제한될 수 있습니다.",
          "5) 회사는 개인(신용)정보를 수집 이용목적 이외에 다른 용도로 이용하거나 회원의 동의 없이 제3자에게 이를 제공하지 않습니다.",
          "6) 회사는 회원의 개인(신용)정보를 수집할 경우 법령상 근거가 없는 한 반드시 회원의 동의를 얻어 수집하며, 회원의 기본적 인권을 침해할 우려가 있는 인종, 출신지, 본적지, 사상, 정치적 성향, 범죄기록, 건강상태 등의 정보는 회원의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.",
          "7) 회사는 회원가입을 만 14세 이상인 경우에 가능하도록 하며 개인(신용)정보의 수집 및 이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인(신용)정보는 법정대리인의 동의를 얻은 경우에만 수집 및 이용합니다.",
          "8) 동반가입의 경우, 일반회원 및 준회원에 준하여 처리합니다.",
        ],
      },
      {
        section: "2. 고객(회원)의 의무",
        subSections: [
          "1) 회원은 자신의 개인(신용)정보를 보호할 의무가 있습니다. 회사의 귀책사유가 없이 개인(신용)정보가 유출되어 발생한 문제에 대해 회사는 책임을 지지 않습니다.",
          "2) 회원은 자신의 개인(신용)정보를 최신의 상태로 유지해야 하며, 회원의 부정확한 정보 입력으로 발생하는 문제의 책임은 회원 자신에게 있습니다.",
          "3) 회원은 아이디, 비밀번호 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.",
          "4) 회원은 타인의 정보를 침해하지 않도록 『신용정보의 이용 및 보호에 관한 법률』, 『정보 통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 정보 등 기타 개인(신용)정보에 관한 법률을 준수하여야 합니다.",
          "5) 동반가입의 경우, 일반회원 및 준회원에 준하여 처리합니다.",
        ],
      },
    ],
  },
  {
    title: "제9조 (정보주체의 권리ㆍ의무 및 행사방법)",
    sections: [
      {
        subSections: [
          "1) 정보주체는 회사에 대해 언제든지 다음 각 호의 개인(신용)정보 보호 관련 권리를 행사할 수 있습니다.",
          "- 개인(신용)정보 열람 요구 및 통지 청구",
          "- 오류 등이 있을 경우 정정 요구",
          "- 삭제 요구 및 동의 철회 요구",
          "- 처리정지 요구",
          "※ 만 14세 미만 아동에 관한 개인(신용)정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인(신용)정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.",
          "2) 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.",
          "3) 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
          "4) 개인(신용)정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. ",
          "5) 개인(신용)정보의 정정 및 삭제 요구는 다른 법령에서 그 개인(신용)정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다",
          "6) 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다",
          "7) 정보주체가 개인(신용)정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인(신용)정보를 이용하거나 제공하지 않습니다.",
          "8) 회사는 정보주체 이외로부터 수집한 개인(신용)정보를 처리하는 때에는 정당한 사유가 없는 한 정보주체의 요구가 있은 날로부터 3일 이내에 수집 출처, 처리 목적, 개인(신용)정보 처리의 정지를 요구할 권리가 있다는 사실을 정보주체에게 알려 드립니다.",
          "9) 회사는 『개인정보보호법』 제20조제4항 각 호에 근거하여 정보주체의 요구를 거부하는 경우에는 정당한 사유가 없는 한 정보주체의 요구가 있은 날로부터 3일 이내에 그 거부의 근거와 사유를 정보주체에게 알려 드립니다.",
          "10) 개인(신용)정보 열람/정정/삭제 청구 및 개인(신용)정보 관련 고충처리 창구",
          "- 주식회사 리트러스트 대표전화 : 051-959-9901",
        ],
      },
    ],
  },
  {
    title: "제 10조 개인(신용)정보의 안전성 확보조치",
    sections: [
      {
        subSections: [
          "1. 개인(신용)정보의 암호화",
          "- 회원의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인(신용)정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 비밀번호는 회원의 생일, 전화번호 등 타인이 추측하기 쉬운 숫자 등을 이용하지 않도록 비밀번호 생성규칙을 수립하여 적용하고 있습니다. 은행계좌번호 및 신용카드번호 등의 개인(신용)정보는 안전한 암호 알고리즘으로 암호화되어 저장 및 관리되고 있습니다.",
          "2. 해킹 등에 대비한 대책",
          "- 회사는 해킹 등 회사 정보통신망 침입에 의해 회원의 개인(신용)정보가 유출되는 것을 방지하기 위해 침입탐지 및 침입차단 시스템을 24시간 가동하고 있습니다. 민감한 개인(신용)정보는 암호화 통신 등을 통하여 네트워크상에서 개인(신용)정보를 안전하게 전송할 수 있도록 하고 있습니다.",
          "3. 개인(신용)정보 취급자의 최소화 및 교육",
          "- 회사는 회사의 개인(신용)정보 취급자를 최소한으로 제한하며, 개인(신용)정보 취급자에 대한 교육 등 관리적 조치를 통해 개인(신용)정보보호의 중요성을 인식시키고 있습니다.",
          "4. 동반가입의 경우, 일반회원 및 준회원에 준하여 처리합니다.",
        ],
      },
    ],
  },
  {
    title: "제 11조 개인(신용)정보보호책임자 및 고충처리 담당부서",
    sections: [
      {
        section: "1. 개인(신용)정보 보호책임자",
        subSections: [
          "1) 성명 : 장우석",
          "2) 직위 : 대표이사",
          "3) 이메일 : support@retrust.world",
          "4) 전화번호 : 0507-1416-8803",
        ],
      },
      {
        section:
          "2. 개인(신용)정보 열람 청구 및 개인(신용)정보 관련 고충처리 담당부서",
        subSections: [
          "1) 부서명 : CS팀",
          "2) 전화번호 : 051-959-9901",
          "- 전화상담은 평일 오전 10시 ~ 오후 6시까지 운영합니다. 이메일, 앱 내 문의하기, 채팅 등을 이용한 상담은 접수 후 24시간 이내에 성실하게 답변하겠습니다. 다만 근무시간 이후 또는 주말 및 공휴일에 접수된 건은 평일 접수 후 익일 처리를 원칙으로 합니다.",
          "3) 이메일 : support@retrust.world",
        ],
      },
    ],
  },
  {
    title: "제12조 권익침해에 대한 구제방법",
    sections: [
      {
        subSections: [
          "1) 정보주체는 아래의 기관을 통해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.",
          "2) 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.",
          "- 한국인터넷진흥원 개인정보침해신고센터 (privacy.kisa.or.kr/국번없이 118)",
          "- 개인정보분쟁조정위원회 (www.kopico.go.kr/1833-6972)",
          "- 대검찰청 사이버수사과 (www.spo.go.kr/국번없이 1301)",
          "- 경찰청 사이버안전국 (ecrm.cyber.go.kr/국번없이 182)",
        ],
      },
    ],
  },
  {
    title: "제13조 개인(신용)정보 처리지침의 변경",
    sections: [
      {
        subSections: [
          "1) 고지의 의무",
          "- 개인(신용)정보 처리지침을 변경할 경우, 홈페이지를 통하여 변경내용, 시행시기를 고지합니다.",
          "- 이 개인(신용)정보처리지침은 2024년 77월 02일부터 적용됩니다.",
          "공고일자 : 2024년 07월 02일",
          "시행일자 : 2024년 07월 02일",
        ],
      },
    ],
  },
];

export default IndividualInfo;
