import React from "react";
import { animated, useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "../../css/Comnons/Modal.module.css";
import { LodingSpot } from "../common/SvgIcon/RestFinishSVG";
import {
  ModalCompleteSvg,
  ModalLodingSvg,
} from "../common/SvgIcon/FinishModalIcon";

export const LodingNotice = () => {
  const spot1Animation = useSpring({
    from: { transform: "translateY(0px)" },
    to: async (next) => {
      while (true) {
        await next({ transform: "translateY(-5px)" });
        await next({ transform: "translateY(0px)" });
      }
    },
    config: { tension: 100, friction: 10 },
  });

  const spot2Animation = useSpring({
    from: { transform: "translateY(0px)" },
    to: async (next) => {
      while (true) {
        await next({ transform: "translateY(-5px)" });
        await next({ transform: "translateY(0px)" });
      }
    },
    config: { tension: 100, friction: 10 },
    delay: 400, // spot1Animation이 시작된 후 400ms 지연
  });

  const spot3Animation = useSpring({
    from: { transform: "translateY(0px)" },
    to: async (next) => {
      while (true) {
        await next({ transform: "translateY(-5px)" });
        await next({ transform: "translateY(0px)" });
      }
    },
    config: { tension: 100, friction: 10 },
    delay: 800, // spot2Animation이 시작된 후 400ms 지연
  });

  return (
    <>
      <ModalLodingSvg />
      <div className={styles.spotsBox}>
        <animated.div style={{ ...spot1Animation, marginLeft: "5px" }}>
          <LodingSpot className={styles.circle} />
        </animated.div>
        <animated.div style={{ ...spot2Animation, marginLeft: "5px" }}>
          <LodingSpot className={styles.circle} />
        </animated.div>
        <animated.div style={{ ...spot3Animation, marginLeft: "5px" }}>
          <LodingSpot className={styles.circle} />
        </animated.div>
      </div>
      <h3 className={styles.h3_Finish}>
        NFT 발급에 시간이 걸릴 수 있어요 <br />
        완료된 후 버튼이 활성화 됩니다.
      </h3>
      <p className={styles.bottomText}>
        NFT 발급이 완료되면 알림톡을 통해서도
        <br />
        받으실 수 있으니 염려 마세요.
      </p>
    </>
  );
};

export const CompleteNotice = () => {
  return (
    <>
      <ModalCompleteSvg />
      <h3 className={styles.h3_Finish}>
        고객님의 NFT 전송이
        <br /> 완료되었습니다.
      </h3>
      <div>
        <p className={styles.middleText}>NFT 보러가기</p>
      </div>
      <p className={styles.bottomText}>
        링크를 누르셔서 카카오톡 KLIP지갑에 저장하세요!!
      </p>
    </>
  );
};

function ModalFinish({ isOpen, onClose, isNFTReceived }) {
  const backgroundAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
  });

  return (
    <>
      <animated.div
        className={styles.aimated_div}
        style={backgroundAnimation}
        onClick={onClose}
      >
        <div className={styles.modalWrap}>
          <div className={styles.closeBOX}>
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.closeIcon}
              onClick={onClose}
            />
          </div>
          {isNFTReceived ? <CompleteNotice /> : <LodingNotice />}
        </div>
      </animated.div>
    </>
  );
}

export default ModalFinish;
