import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../css/Progressbar/Progressbar.module.css";

function SignProgress() {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const pathname = location.pathname;

    if (
      pathname === "/signup/member" ||
      pathname === "/signup/companionmembers"
    ) {
      setActiveStep(2);
    } else if (pathname === "/signup/guarantee") {
      setActiveStep(3);
    } else if (pathname.startsWith("/signup/finish/")) {
      setActiveStep(4);
    } else {
      setActiveStep(1);
    }
  }, [location.pathname]);

  const stepTitles = ["가입자 정보", "청약내용", "결제", "가입완료"];

  return (
    <div className={styles.ProgressbarHeader}>
      <div className={styles.ProgressbarWrap}>
        <div className={styles.Progressbarline}></div>
        <div className={styles.flexCenter}>
          {stepTitles.map((title, index) => (
            <div key={index} className={styles.stepItem}>
              <div className={styles.stepCircle}>
                {activeStep >= index + 1 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx="10" cy="10" r="10" fill="#C1D1C1" />
                    <circle cx="10" cy="10" r="5" fill="#386937" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#B8B9BC"
                    />
                  </svg>
                )}
              </div>
              <p
                className={`${styles.stepText} ${
                  activeStep >= index + 1
                    ? styles.stepText_active
                    : styles.stepText_inactive
                }`}
              >
                {title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SignProgress;
