import React from "react";
import styles from "../../css/Comnons/errorModal.module.css";
import QR_Images from "../../assets/QR_big.svg";
import commonX from "../../assets/commonX.svg";

function QR({ onClose, onHomeClick }) {
  return (
    <div className={styles.errorModalContainer}>
      <div className={styles.overlay}>
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalContentWraps}>
            <div className={styles.commonX}>
              <img src={commonX} alt="닫기" onClick={onClose} />
            </div>
            <div className="w-72">
              <img src={QR_Images} alt="qr" className="w-full object-cover" />
            </div>
            <h3> QR코드를 스캔하시고, 실제 서비스를 이용해보세요.</h3>
            <p>홈으로 이동하실 경우 모든 데이터가 초기화됩니다.</p>
            <button
              type="button"
              className={styles.confirmButton}
              onClick={onHomeClick}
            >
              홈으로
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QR;
