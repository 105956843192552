import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import CalculationProcessRouter from "../pages/calculationProcess/CalculationProcessRouter";
import SignUpProcessRouter from "../pages/signUpProcess/SignUpProcessRouter";
import IndividualInfo from "../components/common/IndividualInfo";
import ServiceAnnounce from "../components/common/ServiceAnnounce";
import Maintenance from "../pages/Maintenance";
import { resetAction } from "../redux/store";
import { useDispatch } from "react-redux";

const timeout = 5 * 60 * 1000;

function InactivityHandler() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        navigate("/");
        dispatch(resetAction());
        sessionStorage.clear();
      }, timeout);
    };

    const activityEvents = ["mousedown", "keydown", "touchstart", "scroll"];
    activityEvents.forEach((event) =>
      document.addEventListener(event, resetTimer)
    );
    resetTimer();

    return () => {
      clearTimeout(timeoutId);
      activityEvents.forEach((event) =>
        document.removeEventListener(event, resetTimer)
      );
    };
  }, [navigate, dispatch]);

  return null;
}

function Router() {
  const [reOrderUser, setReOrderUser] = useState("");

  return (
    <BrowserRouter>
      <InactivityHandler />
      <Routes>
        <Route
          path="/*"
          element={<CalculationProcessRouter setReOrderUser={setReOrderUser} />}
        />
        <Route
          path="/signup/*"
          element={<SignUpProcessRouter reOrderUser={reOrderUser} />}
        />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/serviceInfo" element={<ServiceAnnounce />} />
        <Route path="/individualInfo" element={<IndividualInfo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
