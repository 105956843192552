import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import checkboxStyles from "../../css/CustomChk";
import styles from "../../css/signUpProcessCSS/Announce.module.css";
import Tooltip from "../common/ToolTip";
import { FaGreaterThanSvg } from "../common/SvgIcon/RestFinishSVG";
import commonX from "../../assets/commonX.svg";
import Button from "../common/Button";

function AgreeTerms({ isOpen, onClose, userDataAndCompanionData }) {
  const [agrees, setAgrees] = useState([false, false, false, false, false]);
  const [isAllAgreed, setIsAllAgreed] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  // Load state from session storage on mount
  useEffect(() => {
    const storedAgrees = sessionStorage.getItem("agrees");
    if (storedAgrees) {
      setAgrees(JSON.parse(storedAgrees));
    }
  }, []);

  const toggleAllCheck = () => {
    setIsAllAgreed((prevState) => !prevState);
    CheckAllAgrees();
  };

  useEffect(() => {
    setIsAllAgreed(agrees.every((agreement) => agreement));
  }, [agrees]);

  // Save state to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("agrees", JSON.stringify(agrees));
  }, [agrees]);

  const backgroundAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
  });

  const modalAnimation = useSpring({
    from: { opacity: 0, transform: "translate(-50%, 0%)" },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? "translate(-50%, 0%)" : "translate(-50%, 0%)",
    },
  });

  const CheckAgreement = (index) => {
    const updatedAgrees = [...agrees];
    updatedAgrees[index] = !updatedAgrees[index];
    setAgrees(updatedAgrees);
  };

  const CheckAllAgrees = () => {
    const updatedAgrees = agrees.map(() => !isAllAgreed);
    setAgrees(updatedAgrees);
  };

  const agreementTexts = [
    "단체보험서비스 이용약관",
    "단체보험 규약",
    "개인(신용)정보 조회에 관한 사항",
    "개인(신용)정보 제3자 제공에 관한 사항",
    "개인(신용)정보 수집·이용에 관한 사항",
  ];

  const isRequiredAgreed =
    agrees[0] && agrees[1] && agrees.slice(2).every((agreement) => agreement);

  const handleConfirm = () => {
    if (isRequiredAgreed) {
      onClose();
      navigate("/signup/guarantee");
    } else {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
    }
  };

  return (
    <>
      <animated.div className={styles.aimated_div} style={backgroundAnimation}>
        <animated.div className={styles.animated_div2} style={modalAnimation}>
          <div className={styles.Wrap}>
            <div className={styles.TitleLogoFlex}>
              <h3
                className={styles.h3_agreeTitle}
                style={{ marginBottom: "20px" }}
              >
                보험가입을 위한 다음 약관들에
                <br /> 동의해주세요.
              </h3>
              <div>
                <div onClick={onClose}>
                  <img src={commonX} alt={commonX} />
                </div>
              </div>
            </div>

            <form className="w-full flex flex-col justify-center ">
              <div className={styles.first_agreeTextBox}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles.checkIcon}
                    onClick={toggleAllCheck}
                    style={
                      isAllAgreed
                        ? {
                            background: "#386937",
                            color: "#FFFFFF",
                            border: "1px solid #386937",
                          }
                        : {
                            background: "#E8E9EA",
                            color: "#B8B9BC",
                            border: "1px solid #E8E9EA",
                          }
                    }
                  />
                </div>
                <label htmlFor="chkAll" className={styles.frist_agreeTitle}>
                  전체동의 (대표자 동의)
                </label>
                <input
                  type="checkbox"
                  id="chkAll"
                  hidden
                  style={
                    isAllAgreed
                      ? {
                          ...checkboxStyles.inputCheckbox,
                          ...checkboxStyles.CheckboxChecked,
                        }
                      : checkboxStyles.inputCheckbox
                  }
                  checked={isAllAgreed}
                  onChange={CheckAllAgrees}
                />
              </div>
              {agreementTexts.map((text, index) => (
                <div className={styles.agreeTerms_select} key={index}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={styles.checkIcon}
                      style={
                        agrees[index]
                          ? {
                              background: "#386937",
                              color: "#FFFFFF",
                              border: "1px solid #386937",
                            }
                          : {
                              background: "#E8E9EA",
                              color: "#B8B9BC",
                              border: "1px solid #E8E9EA",
                            }
                      }
                      onClick={() => CheckAgreement(index)}
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "4px" }}>
                    <label
                      htmlFor={`chk${index + 1}`}
                      className={styles.agreeText}
                    >
                      {text}
                    </label>
                    <input
                      type="checkbox"
                      id={`chk${index + 1}`}
                      hidden
                      style={
                        agrees[index]
                          ? {
                              ...checkboxStyles.inputCheckbox,
                              ...checkboxStyles.CheckboxChecked,
                            }
                          : checkboxStyles.inputCheckbox
                      }
                      checked={agrees[index]}
                      onChange={() => CheckAgreement(index)}
                    />
                  </div>
                  <Link
                    to={`/signup/agreeitem/${text}`}
                    className="cursor-pointer"
                  >
                    <FaGreaterThanSvg />
                  </Link>
                </div>
              ))}
            </form>

            <div className={styles.tooltipArea}>
              {showTooltip && (
                <div
                  className={`${styles.tooltip} ${
                    showTooltip ? styles.tooltipVisible : ""
                  }`}
                >
                  <div className={styles.toolTipBox}>
                    <Tooltip />
                    <p className={styles.toolTipTxt}>
                      필수 항목을 체크해야지만 다음 단계로 이동할 수 있습니다.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Button
              buttonText="확인하기"
              onClick={handleConfirm}
              disabled={!isRequiredAgreed}
            />
          </div>
        </animated.div>
      </animated.div>
    </>
  );
}

export default AgreeTerms;
