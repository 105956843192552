import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { selectStartDate } from "../../redux/store";

function StartCalendar({ onClose, faRetrustData }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const minDate = dayjs().startOf("month");
  const maxDate = dayjs().add(3, "month").startOf("month").subtract(1, "day");

  useEffect(() => {
    if (faRetrustData && faRetrustData.startDate) {
      const startDate = dayjs(faRetrustData.startDate);
      setSelectedDate(startDate);
      dispatch(selectStartDate(startDate.format("YYYYMMDD")));
    }
  }, [faRetrustData, dispatch]);

  const shouldDisableDate = (day) => {
    const currentDate = dayjs().format("YYYYMMDD");
    const formattedSelectedDate = dayjs(day).format("YYYYMMDD");
    return dayjs(formattedSelectedDate).isBefore(currentDate);
  };

  const handleApply = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYYMMDD");
      dispatch(selectStartDate(formattedDate));
      onClose(selectedDate);
    } else {
      alert("선택된 날짜가 없습니다!");
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
      firstDayOfWeek={0}
    >
      <div
        className="w-full max-w-[320px] mx-auto lg:max-w-[90%] flex flex-col justify-between"
        style={{ height: "900px" }}
      >
        <div className="calendar-container w-full">
          <style>
            {`
              .MuiDateCalendar-root {
                width: 100% !important;
                max-width: none !important;
                height: auto !important;
                overflow: visible !important;
                padding: 0 16px;
                color: #1b2b3e !important;
              }
              .MuiPickersCalendarHeader-root {
                margin-top: 80px !important;
                margin-bottom: 80px !important;
                padding: 0 !important;
              }
              .MuiPickersArrowSwitcher-root {
                gap: 40px !important;
              }
              .MuiPickersArrowSwitcher-button {
                padding: 12px !important;
              }
              .MuiPickersArrowSwitcher-button svg {
                width: 36px !important;
                height: 36px !important;
              }
              .MuiDayCalendar-header, .MuiDayCalendar-weekContainer {
                justify-content: space-between !important;
                margin: 0 !important;
              }
              .MuiDayCalendar-header {
                margin-bottom: 16px !important;
              }
              .MuiDayCalendar-weekContainer {
                margin-bottom: 16px !important;
              }
              .MuiDayCalendar-weekContainer:last-child {
                margin-bottom: 0 !important;
              }
              .MuiDayCalendar-slideTransition {
                overflow: visible !important;
                height: auto !important;
              }
              .MuiTypography-root {
                font-size: 1.1rem !important;
                font-weight: 600 !important;
              }
              .MuiPickersDay-root {
                margin: 0 !important;
                font-size: 1.1rem !important;
                font-weight: 400 !important;
                width: 40px !important;
                height: 40px !important;
                border: none !important;
              }
              .MuiPickersDay-root.Mui-selected {
                background-color: #386937 !important;
                color: white !important;
              }
              .MuiPickersDay-root.MuiPickersDay-today {
                border: none !important;
              }
              .MuiPickersDay-root.MuiPickersDay-today::after {
                content: '';
                position: absolute;
                bottom: 4px;
                left: 50%;
                transform: translateX(-50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #386937;
              }
              .MuiPickersCalendarHeader-label {
                font-size: 1.8rem !important;
                font-weight: 600 !important;
              }
              .MuiMonthCalendar-root {
                width: 100% !important;
                max-width: none !important;
                display: grid !important;
                grid-template-columns: repeat(3, 1fr) !important;
                gap: 16px !important;
              }
              .MuiMonthCalendar-root .MuiPickersMonth-root {
                width: 100% !important;
                margin: 0 !important;
              }
              .MuiMonthCalendar-root .MuiPickersMonth-monthButton {
                width: 100% !important;
                padding: 24px 4px !important;
                font-size: 1.2rem !important;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                height: auto !important;
              }
              .MuiMonthCalendar-root .MuiPickersMonth-monthButton > span {
                transform: none !important;
                writing-mode: horizontal-tb !important;
              }
              .MuiDayCalendar-weekDayLabel:first-child,
              .MuiPickersDay-root:first-child {
                color: #e57373 !important;
              }
              .MuiDayCalendar-weekDayLabel:nth-child(7),
              .MuiPickersDay-root:nth-child(7) {
                color: #4a90e2 !important;
              }
              .MuiPickersDay-root.Mui-disabled {
                opacity: 0.5 !important;
              }
              @media (min-width: 1024px) {
                .MuiDateCalendar-root {
                  font-size: 1.2rem;
                  padding: 0 24px;
                }
                .MuiTypography-root {
                  font-size: 1.2rem !important;
                }
                .MuiPickersDay-root {
                  width: 48px !important;
                  height: 48px !important;
                  font-size: 1.2rem !important;
                }
                .MuiDayCalendar-weekContainer {
                  margin-bottom: 24px !important;
                }
                .MuiPickersCalendarHeader-label {
                  font-size: 2rem !important;
                }
                .MuiPickersArrowSwitcher-button svg {
                  width: 44px !important;
                  height: 44px !important;
                }
                .MuiMonthCalendar-root {
                  gap: 24px !important;
                }
                .MuiMonthCalendar-root .MuiPickersMonth-monthButton {
                  padding: 32px 8px !important;
                  font-size: 1.3rem !important;
                }
              }
            `}
          </style>
          <DateCalendar
            value={selectedDate}
            onChange={setSelectedDate}
            shouldDisableDate={shouldDisableDate}
            showDaysOutsideCurrentMonth={false}
            minDate={minDate}
            maxDate={maxDate}
            views={["month", "day"]}
            className="custom-calendar w-full"
          />
        </div>

        <div className="w-full lg:max-w-[100%] lg:mx-auto">
          <div className="mt-4 border-t-2"></div>
          <div className="py-4 lg:py-6">
            <button
              className="w-full mx-auto text-center py-5 rounded-2xl bg-[#386937] text-white font-semibold text-lg lg:w-[50%] lg:flex lg:justify-center lg:py-6 lg:text-xl"
              onClick={handleApply}
            >
              적용
            </button>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default StartCalendar;
