import React from "react";
import styles from "../../css/Comnons/Button.module.css";

function Button({
  buttonText = "확인하기",
  onClick,
  disabled,
  andColor,
  isFinishPath,
}) {
  return (
    <>
      <div className={styles.buttonWrap}>
        <div
          className={isFinishPath ? styles.finishButtonBOX : styles.buttonBox}
        >
          <button
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            className={styles.buttonStyle}
            style={{
              backgroundColor: disabled
                ? "#C3D2C3"
                : "#386937" && andColor
                ? "#C3D2C3"
                : "#386937" && isFinishPath
                ? "#1F3A1E"
                : null,
            }}
            disabled={disabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
}

export default Button;
