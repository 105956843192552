import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import styles from "../../css/Intros.module.css";

import IntroBanner from "../../assets/IntroBanner.svg";
import introMainBanner from "../../assets/introMainBanner.svg";
import introMainBanner2 from "../../assets/introMainBanner2.svg";
import introMainBanner3 from "../../assets/introMainBanner3.svg";
import pointBanner from "../../assets/pointBanner.svg";
import Footer from "../../components/common/Footer";

function Intro({ setShowHeader, setShowbar, setReOrderUser }) {
  const [reOrderData, setReOrderData] = useState(null);
  const navigate = useNavigate();
  const navigation = () => {
    navigate("/insert", { state: { reOrderData } });
  };

  useEffect(() => {
    const handleReOrder = async () => {
      try {
        const response = await fetch("/trip-api/auth/reOrder", {
          method: "GET",
          redirect: "follow",
        });
        const result = await response.json();
        setReOrderData(result);
        setReOrderUser(result);
        return true;
      } catch (error) {
        return false;
      }
    };
    handleReOrder();
  }, [setReOrderData, setReOrderUser]);

  useEffect(() => {
    setShowHeader(false);
    setShowbar(false);

    return () => {
      setShowHeader(true);
      setShowbar(true);
    };
  }, [setShowHeader, setShowbar]);

  return (
    <>
      <div className={styles.ContentsSection}>
        <section className={styles.imgBoxSection}>
          <img src={IntroBanner} alt="IntroTitleBanner" />
        </section>
        <section>
          <div className={styles.imageContentsWrap}>
            <img src={introMainBanner} alt="" />
            <img src={introMainBanner2} alt="" />
            <img src={introMainBanner3} alt="" />
          </div>
        </section>
        <section className={styles.textWrap}>
          <article>
            <div className={styles.divingLine}></div>
            <p className="mb-4">
              준법감시인 심의필 제2024-광고-1043호
              <br />
              (2024.06.05~2025.06.04)
            </p>
            <img src={pointBanner} alt="pointBanner" />
            <br />
            <ul className={styles.eventText}>
              <li className="">
                <span>・</span>
                <p>
                  포인트 지급은 보험기간 종료 후 1개월 내로 문자로 보내드립니다.
                </p>
              </li>
              <li className="">
                <span>・</span>
                <p>보험료 10,000원 이하일 경우 지급되지 않습니다.</p>
              </li>
              <li className="">
                <span>・</span>
                <p>
                  3개월 이상 업무, 출장, 유학, 연수, 캠프, 주재원(장기파견포함),
                  주재원 가족은 해외장기체류로 가입하시기 바랍니다.
                </p>
              </li>
            </ul>
            <p className={styles.reward}>
              [리트러스트 포인트 리워드 프로모션 유의사항]
            </p>
            <ul className={styles.listStyle}>
              <li>
                <span>・</span>
                <p>
                  상품소개동의 미동의할 경우, 리트러스트 포인트 리워드가 지급이
                  되지 않습니다.
                </p>
              </li>
              <li>
                <span className="">・</span>
                <p>
                  리트러스트 앱에 등록된 성명, 휴대전화번호가 보험계약자와
                  일치해야 혜택이 제공됩니다.
                </p>
              </li>

              <li>
                <span className="">・</span>
                <p>지급일에 리트러스트 회원이 아닐 경우 지급되지 않습니다.</p>
              </li>

              <li>
                <span className="">・</span>
                <p>지급일 이전 보험계약이 취소될 경우 지급되지 않습니다.</p>
              </li>

              <li style={{ margin: "12px 0 12px 0" }}>
                <span className="">・</span>
                <p>
                  이 보험계약은 예금자보호법에 따라 예금보험공사가 보호하되,
                  보호 한도는 본 보험회사에 있는 귀하의 모든 예금보호 대상
                  금융상품의 해약환급금(또는 만기 시 보험금이나 사고보험금)에
                  기타 지급금을 합하여 1인당 "최고 5천만원"이며, 5천만원을
                  초과하는 나머지 금액은 보호하지 않습니다. 단, 보험계약자 및
                  보험료 납부자가 법인인 보험계약은 보호되지 않습니다.
                </p>
              </li>

              <li>
                <span className="">・</span>
                <p>
                  보험계약을 체결하기 전에 상품설명서 및 약관을 읽어보시기
                  바랍니다.
                </p>
              </li>
            </ul>
          </article>
        </section>
      </div>
      <Footer />
      <Button buttonText="보험료 확인하기" onClick={navigation} />
    </>
  );
}
export default Intro;
