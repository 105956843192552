import React from "react";
import styles from "../../css/Comnons/errorModal.module.css";
import errorModal from "../../assets/errorModal.svg";
import commonX from "../../assets/commonX.svg";

function ErrorModal({ message, onClose, subMsg }) {
  return (
    <div className={styles.errorModalContainer}>
      <div className={styles.overlay}>
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.modalContentWrap}>
            <div className={styles.commonX}>
              <img src={commonX} alt="닫기" onClick={onClose} />
            </div>
            <div className={styles.imageWrap}>
              <img src={errorModal} alt="Error" />
            </div>
            <h3>{message}</h3>
            <p>{subMsg}</p>
            <button
              type="button"
              className={styles.confirmButton}
              onClick={onClose}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
