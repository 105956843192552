import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 여전히 동기적 상태 업데이트를 위해 사용
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    // 비동기적으로 상태 업데이트
    setTimeout(() => {
      this.setState({ hasError: true });
    }, 0);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-message">
          <h1>죄송합니다. 문제가 발생했습니다.</h1>
          <button onClick={() => window.location.reload()}>
            페이지 새로고침
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
