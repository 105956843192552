import styles from "../../css/calculationProcessCSS/modifyModal.module.css";
import { useNavigate } from "react-router-dom";
import commonX from "../../assets/commonX.svg";

function ModifyModal({ isOpen, onClose }) {
  const navigate = useNavigate();

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.modalContentWrap}>
            <div className={styles.modalContentWrap_Title}>
              <h3>변경 메뉴를 선택해주세요.</h3>
              <img src={commonX} alt="닫기" onClick={onClose} />
            </div>
            <div className={styles.redirectBtnContents}>
              <ul>
                <li
                  onClick={() => {
                    navigate("/trip/insert");
                  }}
                >
                  <p>일정변경 및 사용자 정보 변경</p>
                </li>
                <li
                  onClick={() => {
                    navigate("/trip/trip");
                  }}
                >
                  <p>여행지 변경</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ModifyModal;
