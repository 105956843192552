import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import ConfirmAndGuarantee from "../../components/common/ConfrimAndGuarantee";
import QR from "../../components/modal/QR";
import { resetAction } from "../../redux/store";

function GuaranteeChk() {
  const [showQRModal, setShowQRModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePaymentClick = (e) => {
    if (e) {
      e.preventDefault(); // Prevents default behavior and improves touch responsiveness
    }
    setShowQRModal(true);
  };

  const handleCloseQRModal = () => {
    setShowQRModal(false);
  };

  const handleHomeClick = () => {
    dispatch(resetAction());
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      <ConfirmAndGuarantee />
      <Button buttonText="결제하기" onClick={handlePaymentClick} />
      {showQRModal && (
        <QR onClose={handleCloseQRModal} onHomeClick={handleHomeClick} />
      )}
    </>
  );
}

export default GuaranteeChk;
