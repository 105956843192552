// Import necessary components and dependencies
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../../components/common/Header";
import SignProgress from "../../components/signUpProcessPart/SignProgress";
import Member from "../../pages/signUpProcess/Member";
import CompanionMembers from "../../pages/signUpProcess/CompanionMembers";
import GuaranteeChk from "../../pages/signUpProcess/GuaranteeChk";
import Finish from "../../pages/signUpProcess/Finish";
import AgreeContents from "../../components/signUpProcessPart/AgreeContents";
import agreeContentsItem from "../../api/agreeContentsItem";
import NotFoundPage from "../NotFoundPage";
import useScrollToTop from "../../hook/useNavigateWithScrollToTop";

function SignUpProcessRouter({ reOrderUser }) {
  useScrollToTop();

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/member"
        element={
          <>
            <Header TitleText={true} />
            <SignProgress />
            <Member reOrderUser={reOrderUser} />
          </>
        }
      />
      <Route
        path="/companionmembers"
        element={
          <>
            <Header TitleText={true} />
            <SignProgress />
            <CompanionMembers />
          </>
        }
      />

      <Route
        path="/agreeitem/:agreement"
        element={<AgreeContents agreeContentsItem={agreeContentsItem} />}
      />
      <Route
        path="/guarantee"
        element={
          <>
            <Header TitleText={true} />
            <SignProgress />
            <GuaranteeChk />
          </>
        }
      />
      <Route
        path="/finish/:tid"
        element={
          <>
            <Header TitleText={true} />
            <SignProgress />
            <Finish />
          </>
        }
      />
    </Routes>
  );
}

export default SignUpProcessRouter;
