import React from "react";
import ConfirmNotice from "../../components/calculationProcessPart/ConfirmNotice";
import confirmPDF_1 from "../../assets/confirmPDF_1.svg";
import confirmPDF_2 from "../../assets/confirmPDF_2.svg";
import styles from "../../css/Comnons/ConfirmPDF.module.css"

function ConfirmPDF() {
  const downloadPDF = (pdfPath, fileName) => {
    const link = document.createElement("a");
    link.href = pdfPath;
    link.setAttribute("download", fileName);
    link.click();
  };

  return (
    <>
      <div className={styles.confirmContents}>
        <div
          className={styles.PDF_style}
          onClick={() => downloadPDF("/pdf/ProductDescription.pdf", "ProductDescription.pdf")}
        >
          상품설명서(PDF)
          <img src={confirmPDF_1} alt="pdf1" />
        </div>

        <div
          className={styles.PDF_style}
          onClick={() => downloadPDF("/pdf/InsurancePolicy.pdf", "InsurancePolicy.pdf")}
        >
          보험약관(PDF)
          <img src={confirmPDF_2} alt="pdf2" />
        </div>
      </div>
      <ConfirmNotice />
    </>
  );
}

export default ConfirmPDF;
