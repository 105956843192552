import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/calculationProcessCSS/indemnity.module.css";
import { selectData, setPriceData } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Button from "../../components/common/Button";
import PlanData from "../../api/PlanData.json";
import meritzLogo from "../../assets/meritzLogo.svg";
import Loading from "../../components/modal/Loading";

const Indemnity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state
  const selectedData = useSelector((state) => state.plan.selectedData);
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const endDate = useSelector((state) => state.calendar.selectedEndDate);
  const gender = useSelector((state) => state.user.gender);
  const dateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const companions = useSelector((state) => state.companions);
  const recommendedCountry = useSelector(
    (state) => state.country.recommendedCountryData
  );
  const selectedCountryData = useSelector(
    (state) => state.country.selectedCountryData
  );
  const priceData = useSelector((state) => state.priceData.priceData);
  const userInfo = useSelector((state) => state.user);

  // Derived state
  const userPersonnel = useMemo(() => [userInfo].length, [userInfo]);
  const companionsPersonnel = useMemo(() => companions.length, [companions]);
  const totalPersonnel = useMemo(
    () => userPersonnel + companionsPersonnel,
    [userPersonnel, companionsPersonnel]
  );

  // Local state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priceActivated, setPriceActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Calculate national code
  const natlCds = useMemo(() => {
    if (selectedCountryData && selectedCountryData.cityNatlCd) {
      return selectedCountryData.cityNatlCd;
    } else if (recommendedCountry && recommendedCountry.cityNatlCd) {
      return recommendedCountry.cityNatlCd;
    } else {
      return null;
    }
  }, [selectedCountryData, recommendedCountry]);

  // Fetch data effect
  useEffect(() => {
    const fetchIndemnityData = async () => {
      if (!startDate || !endDate || !natlCds || totalPersonnel === 0) {
        return;
      }

      const url = `${process.env.REACT_APP_BASE_URL}/trip-api/price`;
      const userInfoData = {
        inspeNm: "사용자",
        inspeBdt: dateOfBirth,
        gndrCd: gender,
      };

      const companionInfoData = companions.map((companion, index) => ({
        inspeNm: `동반자${index + 1}`,
        inspeBdt: companion.dateOfBirth,
        gndrCd: companion.gender,
      }));

      const data = {
        insBgnDt: startDate,
        insEdDt: endDate,
        natlCd: natlCds,
        inspeCnt: totalPersonnel,
        inspeInfos: [userInfoData, ...companionInfoData],
      };

      try {
        setIsLoading(true);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(`알 수 없는 오류입니다.: ${response.status}`);
        } else {
          const result = await response.json();
          console.log(result);
          dispatch(setPriceData(result));
        }
      } catch (error) {
        console.error("실패:", error);
        setErrorMessage("데이터를 불러오는 중 오류가 발생했습니다.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchIndemnityData();
  }, [
    startDate,
    endDate,
    natlCds,
    totalPersonnel,
    dateOfBirth,
    gender,
    companions,
    dispatch,
  ]);

  useEffect(() => {
    const storedPriceActivated = sessionStorage.getItem("priceActivated");
    if (storedPriceActivated !== null) {
      setPriceActivated(storedPriceActivated === "true");
    }
  }, []);

  const priceToString = useCallback((price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const handleItemClick = useCallback(
    (platform, index) => {
      dispatch(selectData(platform));
      sessionStorage.setItem("selectedDataIndex", index);
      if (platform.name === "메리츠 추천 플랜") {
        setShowConfirmModal(true);
      }
    },
    [dispatch]
  );

  const handleConfirm = useCallback(() => {
    if (!priceActivated) {
      setErrorMessage("추천 플랜 가격을 활성화 후 이용해주세요.");
    } else {
      sessionStorage.setItem("selectedPlan", selectedData.name);
      navigate("/confirm");
    }
  }, [priceActivated, selectedData, navigate]);

  const handleRecommendationClick = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setPriceActivated(true);
    sessionStorage.setItem("priceActivated", "true");
    setShowConfirmModal(false);
  }, []);

  const memoizedPlatforms = useMemo(() => {
    return PlanData.insurancePlans.map((platform, index) => {
      const isSelected = selectedData && selectedData.name === platform.name;

      let priceText = "???";
      if (priceData) {
        switch (platform.name) {
          case "메리츠 알뜰 플랜":
            priceText =
              priceData.BASIC?.ttPrem !== undefined
                ? `${priceToString(priceData.BASIC.ttPrem)}원`
                : "???";
            break;
          case "메리츠 고급 플랜":
            priceText =
              priceData.PREMIUM?.ttPrem !== undefined
                ? `${priceToString(priceData.PREMIUM.ttPrem)}원`
                : "???";
            break;
          case "메리츠 추천 플랜":
            if (priceActivated) {
              priceText =
                priceData.RECOMMEND?.ttPrem !== undefined
                  ? `${priceToString(priceData.RECOMMEND.ttPrem)}원`
                  : "???";
            }
            break;
          default:
            break;
        }
      }

      return (
        <div
          key={index}
          className={`${styles.wrap} ${isSelected ? styles.selected : ""}`}
          onClick={() => handleItemClick(platform, index)}
        >
          <div className={styles.ContentsFlexRow}>
            <div className={styles.ContentsImgWrap}>
              <img src={meritzLogo} alt="logo" className={styles.ContentsImg} />
            </div>
            <div className={styles.platformInfoWrap}>
              <span className={styles.platformName}>{platform.name}</span>
              <div className={styles.platformPrice}>{priceText}</div>
            </div>
          </div>
          <div className={styles.platformDetailWrap}>
            {index === 0 && (
              <>
                <p>해외 출장이나 가볍게 떠나는 여행이세요?</p>
                <p>경제적인 여행, 합리적인 보장</p>
              </>
            )}
            {index === 1 && (
              <>
                <p>해양 엑티비티 계획이 있으세요?</p>
                <p>스카이 다이빙, 행글라이딩, 스쿠버 다이빙 등 보장</p>
              </>
            )}
            {index === 2 && (
              <>
                <p>국가별, 특징을 고려한 고객 맞춤 보장!</p>
                <p>안전하고 안심할 수 있는 최적의 플랜 제공</p>
              </>
            )}
          </div>
          {platform.name === "메리츠 추천 플랜" && (
            <div
              className={styles.recommandModalBtn}
              onClick={handleRecommendationClick}
            >
              <p className={styles.recommandModaTitle}>
                추천 하는 이유를 알고싶다면?
              </p>
            </div>
          )}
        </div>
      );
    });
  }, [
    priceData,
    priceActivated,
    priceToString,
    handleItemClick,
    selectedData,
    handleRecommendationClick,
  ]);

  return (
    <div className={styles.titltBox}>
      <div className={styles.indemnityContents}>
        <div className={styles.indemnityWrap}>
          <div className={styles.title}>
            <p>어떤 플랜이 좋으신가요? </p>
            <div className={styles.subTitle}>
              <p>최적화된 플랜</p>을 추천드려요
            </div>
          </div>
          {isLoading ? <Loading /> : memoizedPlatforms}
          <ConfirmModal isOpen={showConfirmModal} onClose={handleModalClose} />
          <div className={styles.buttonWrapper}>
            <Button
              onClick={handleConfirm}
              disabled={!selectedData}
              buttonText="확인하기"
            />
          </div>
          {errorMessage && (
            <ErrorModal
              message={errorMessage}
              onClose={() => setErrorMessage("")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Indemnity;
