// Header.js
import { Link, useNavigate } from "react-router-dom";
import commonLeftArrow from "../../assets/commonLeftArrow.svg";
import introHome from "../../assets/introHome.svg";
import styles from "../../css/Comnons/header.module.css";

function Header({ TitleText, isSticky }) {
  const navigate = useNavigate();
  const prevPage = () => {
    navigate(-1);
  };
  return (
    <div className={`${styles.headers} ${isSticky ? "fixed " : ""}`}>
      <div className={styles.headersContents}>
        <div className={styles.headersContentsFlex}>
          {/* Left Arrow Icon */}
          <span onClick={prevPage}>
            <img src={commonLeftArrow} alt={commonLeftArrow} />
          </span>
          {/* Title Text */}
          <p>{TitleText ? "여행자 보험 가입" : "여행자 보장 선택"}</p>
          {/* Home Icon */}
          <div>
            <Link to="/">
              <img src={introHome} alt={introHome} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
