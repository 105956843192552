import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/Button";
import CustomInput from "../../components/common/CustomInput";
import AgreeTerms from "../../components/modal/AgreeTerms";
import styles from "../../css/signUpProcessCSS/Member.module.css";
import { useNavigate } from "react-router-dom";
import { setMembersData } from "../../redux/store";

function Member({ reOrderUser }) {
  const navigate = useNavigate();
  const companions = useSelector((state) => state.companions);
  const dateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const gender = useSelector((state) => state.user.gender);
  const reOrderUserData = reOrderUser?.user || {}; // undefined 방지
  console.log(reOrderUser);

  const getGenderCode = (birth, gender) => {
    const year = parseInt(birth.substring(0, 4));
    const baseCode = year >= 2000 ? 2 : 0;
    return (baseCode + parseInt(gender)).toString();
  };

  const dispatch = useDispatch();
  // Initialize state variables with sessionStorage values or defaults
  const [name, setName] = useState(
    sessionStorage.getItem("name") ||
      reOrderUserData.name || // 순서를 변경했습니다.
      ""
  );
  const [englishName, setEnglishName] = useState(
    (
      sessionStorage.getItem("englishName") ||
      reOrderUserData.name_english || // 순서를 변경했습니다.
      ""
    ).toUpperCase()
  );

  const [phoneNumber, setPhoneNumber] = useState(
    sessionStorage.getItem("phoneNumber") ||
      reOrderUserData.phone || // 순서를 변경했습니다.
      ""
  );
  const [email, setEmail] = useState(
    sessionStorage.getItem("email") ||
      reOrderUserData.email || // 순서를 변경했습니다.
      ""
  );
  const formattedDate = dateOfBirth.slice(2, 8);

  const [error, setError] = useState({
    name: "",
    englishName: "",
    phoneNumber: "",
    email: "",
  });
  const [showModal, setShowModal] = useState(false); // 모달 상태

  useEffect(() => {
    const storedShowModal = sessionStorage.getItem("showModal");
    if (storedShowModal) {
      setShowModal(JSON.parse(storedShowModal));
    }
  }, []);

  useEffect(() => {
    // showModal 상태가 변경될 때 sessionStorage에 저장
    sessionStorage.setItem("showModal", JSON.stringify(showModal));
  }, [showModal]);

  useEffect(() => {
    // companions 상태가 변경될 때 showModal 상태 초기화 및 sessionStorage에서 삭제
    if (companions && companions.length > 0) {
      setShowModal(false); // 동반인 정보가 있으면 모달을 닫음
      sessionStorage.setItem("showModal", JSON.stringify(false));
    }
  }, [companions]);

  useEffect(() => {
    // 페이지 이동 시 sessionStorage에 모달 상태 저장
    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("showModal", JSON.stringify(showModal));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showModal]);

  const handleEnglishNameChange = (value) => {
    value = value.replace(/[^a-zA-Z\s]/g, "").toUpperCase();
    setEnglishName(value);
    sessionStorage.setItem("englishName", value);
  };

  const handlePhoneNumberChange = (value) => {
    value = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");
    setPhoneNumber(value);
    sessionStorage.setItem("phoneNumber", value);
  };

  const handleEmailChange = (value) => {
    value = value.replace(/[^a-zA-Z0-9@._%+-]/g, "");
    setEmail(value);
    sessionStorage.setItem("email", value);
  };

  const isValidPhone = (phoneNumber) => {
    const regex = /^01[0-9]-[0-9]{3,4}-[0-9]{4}$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return regex.test(email);
  };

  const isValidKoreanName = (name) => {
    const koreanRegex = /^[가-힣]+$/;
    return koreanRegex.test(name);
  };

  const signUp = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let nameError = "";
    let englishNameError = "";
    let phoneNumberError = "";
    let emailError = "";

    if (!name.trim()) {
      nameError = "이름을 입력해주세요.";
    } else if (!isValidKoreanName(name)) {
      nameError = "올바른 한글 이름을 입력해주세요.";
    }
    if (!englishName.trim()) {
      englishNameError = "올바른 영문 이름을 입력해주세요.";
    }
    if (!phoneNumber.trim()) {
      phoneNumberError = "전화번호를 입력해주세요.";
    } else if (!isValidPhone(phoneNumber)) {
      phoneNumberError = "유효하지 않은 전화번호입니다!";
    }
    if (!email.trim()) {
      emailError = "이메일을 입력해주세요.";
    } else if (!isValidEmail(email)) {
      emailError = "유효하지 않은 이메일 주소입니다.";
    }

    setError({
      name: nameError,
      englishName: englishNameError,
      phoneNumber: phoneNumberError,
      email: emailError,
    });

    if (!nameError && !englishNameError && !phoneNumberError && !emailError) {
      const queryData = {
        name,
        englishName,
        phoneNumber,
        email,
        dateOfBirth,
      };

      if (companions && companions.length > 0) {
        navigate("/signup/companionmembers", { state: queryData });
      } else {
        dispatch(setMembersData(queryData)); // modalData 업데이트
        setShowModal(true); // 모달 열기
      }
    }
  };

  const isAllEmpty = () => {
    return (
      !name.trim() &&
      !englishName.trim() &&
      !phoneNumber.trim() &&
      !email.trim()
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    sessionStorage.setItem("showModal", JSON.stringify(false));
  };

  return (
    <div>
      <section className="w-full">
        <form className={styles.memberForm} onSubmit={signUp}>
          <h3 className={styles.H3_title}>
            보험가입에 필요한 사용자 정보를 제공해주세요
          </h3>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>이름</label>
            <CustomInput
              placeholder="이름(3~4자)"
              maxLength={4}
              value={name}
              error={error.name}
              onChange={(value) => {
                setName(value);
                sessionStorage.setItem("name", value);
              }}
              onClear={() => {
                setName("");
                sessionStorage.removeItem("name");
              }}
            />
            {error.name && (
              <p style={{ marginTop: "8px", color: "#E86565" }}>{error.name}</p>
            )}
          </div>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>주민번호</label>
            <div className={styles.identifyNum}>
              <CustomInput
                placeholder="앞자리"
                readOnly
                value={formattedDate}
              />
              <div>
                <span>-</span>
              </div>
              <CustomInput
                placeholder="뒷자리"
                readOnly
                value={getGenderCode(dateOfBirth, gender) + "******"}
              />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>
              영문명 (영문가입 증명서용)
            </label>
            <CustomInput
              placeholder="예) HONG GIL DONG (여권과 동일하게 입력 하세요.)"
              maxLength={16}
              value={englishName}
              error={error.englishName}
              onChange={(value) => {
                handleEnglishNameChange(value);
              }}
              onClear={() => {
                setEnglishName("");
                sessionStorage.removeItem("englishName");
              }}
            />
            {error.englishName && (
              <p style={{ marginTop: "8px", color: "#E86565" }}>
                {error.englishName}
              </p>
            )}
          </div>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>휴대폰 번호</label>
            <CustomInput
              placeholder="'-'빼고 입력해주세요."
              type="tel"
              maxLength={13}
              value={phoneNumber}
              onChange={(value) => {
                handlePhoneNumberChange(value);
              }}
              onClear={() => {
                setPhoneNumber("");
                sessionStorage.removeItem("phoneNumber");
              }}
              error={error.phoneNumber}
            />
            {error.phoneNumber && (
              <p style={{ marginTop: "8px", color: "#E86565" }}>
                {error.phoneNumber}
              </p>
            )}
          </div>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>이메일</label>
            <CustomInput
              placeholder="이메일"
              type="email"
              maxLength={25}
              value={email}
              onChange={(value) => {
                handleEmailChange(value);
              }}
              onClear={() => {
                setEmail("");
                sessionStorage.removeItem("email");
              }}
              error={error.email}
            />
            {error.email && (
              <p style={{ marginTop: "8px", color: "#E86565" }}>
                {error.email}
              </p>
            )}
          </div>
        </form>
      </section>
      <div className="py-4">
        <Button
          buttonText={
            companions && companions.length > 0
              ? "동반인 정보 입력"
              : "가입하기"
          }
          onClick={signUp}
          disabled={isAllEmpty()}
        />
      </div>
      {showModal && <AgreeTerms isOpen={true} onClose={handleCloseModal} />}
    </div>
  );
}

export default Member;
